import React from "react"
import {Router} from "@reach/router"
import Checkout from '../components/Checkout'
import Layout from '../components/Layout'
import Helmet from "react-helmet"
import Breadcrumb from "../components/Breadcrumb"
import {I18nextContext} from 'gatsby-plugin-react-i18next'
import {useSiteMetadata} from "../hooks/use-site-metadata"
import favicon from "../../static/favicon.svg"

export default function CheckoutPage() {
  const {language} = React.useContext(I18nextContext);
  const {title} = useSiteMetadata();

  return (
    <Layout>
      <Helmet>
        <title>{title}</title>
        <link rel="icon" href={favicon} />
        <meta name="title" content={title}/>
        <meta name="og:title" content={title} />
        <meta name={`robots`} content={`noindex, nofollow`} />
      </Helmet>
      <Router basepath={`/${language}/checkout/`}>
        <Breadcrumb path="/" step={'compra'}/>
      </Router>
      <Router basepath={`/${language}/checkout/`}>
        <Checkout path="/"></Checkout>
      </Router>
    </Layout>
  )
}
